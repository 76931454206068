import { Button, RoundIcon, GridMenu, Link } from "@whitespace/components";
import { visuallyHidden } from "@whitespace/components/dist/utils/styles.module.css";
import { useSearchSettings } from "@whitespace/gatsby-plugin-search/src/hooks";
import { Image } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import { useMenu } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/menus";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/page-context";
import clsx from "clsx";
import { navigate } from "gatsby";
import PropTypes from "prop-types";
import qs from "query-string";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useRekai } from "../hooks/rekai";

import * as defaultStyles from "./FrontPageHeader.module.css";
import LayoutComponent from "./LayoutComponent";

FrontPageHeader.propTypes = {
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
};

export default function FrontPageHeader({
  styles = defaultStyles,
  wrapperClassName,
  className,
  ...restProps
}) {
  const inputRef = useRef(null);
  const [searchInput, setSearchInput] = useState("");

  const { contentNode: { featuredImage: hero, uri } = {} } = usePageContext();

  const { searchPlaceholderText, searchLabelText, searchButtonText } =
    useSearchSettings();

  const { t } = useTranslation();

  const { predictions: shortcuts } = useRekai({ nrofhits: 4 }) || {};

  let menu = useMenu("HEADER_TABS_MENU");

  return (
    <div className={clsx(styles.component, className)} {...restProps}>
      {hero?.node && (
        <div className={clsx(styles.hero, wrapperClassName)}>
          <Image
            {...hero.node}
            caption={null}
            credit={null}
            aspectRatio={1440 / 500}
            className={clsx(styles.image)}
          />
          <LayoutComponent className={styles.search} type={"fullNoMargin"}>
            <form
              className={clsx(styles.form)}
              role="search"
              onSubmit={(event) => {
                event.preventDefault();
                navigate(`/sok?${qs.stringify({ q: searchInput })}`);
              }}
              {...restProps}
            >
              <label className={visuallyHidden} htmlFor="search">
                {searchLabelText}
              </label>
              <input
                className={clsx(styles.input)}
                type="search"
                name="search"
                ref={inputRef}
                value={searchInput}
                onChange={(event) => {
                  setSearchInput(event.target.value);
                }}
                placeholder={searchPlaceholderText}
              />
              <Button
                type="submit"
                ariaLabel={searchButtonText}
                className={clsx(styles.submit)}
              >
                <div>
                  <RoundIcon name="search" />
                </div>
              </Button>
            </form>
          </LayoutComponent>
        </div>
      )}

      {shortcuts && (
        <div>
          <LayoutComponent
            className={clsx(styles.recommendationWrapper)}
            type={"fullNoMargin"}
          >
            <ul className={clsx(styles.recommendationList, wrapperClassName)}>
              {shortcuts.map(({ title, url }, index) => {
                return (
                  <li key={index} className={clsx(styles.recommendationItem)}>
                    <RoundIcon
                      name="arrow-right-1"
                      backgroundColor={"#5287b0"}
                      iconProps={{ size: "12px", color: "#fff" }}
                    />
                    <Link to={url}>{title}</Link>
                  </li>
                );
              })}
            </ul>
          </LayoutComponent>
        </div>
      )}
      {menu?.items && (
        <div className={clsx(styles.tabsMenu, wrapperClassName)}>
          <LayoutComponent className={""} type={"fullNoMargin"}>
            <GridMenu
              {...restProps}
              items={menu.items.map((item) => ({
                ...item,
                icon: { name: item.extra.icon },
              }))}
              className={clsx(styles.gridMenu)}
              aria-label={t("Primary navigation")}
            />
          </LayoutComponent>
        </div>
      )}
    </div>
  );
}
