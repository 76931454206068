import { H } from "@jfrk/react-heading-levels";
import {
  Button as DefaultButton,
  Link as DefaultLink,
  withComponentDefaults,
} from "@whitespace/components";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import * as defaultStyles from "./BoxNavigation.module.css";

BoxNavigation.propTypes = {
  className: PropTypes.string,
  components: PropTypes.objectOf(PropTypes.elementType),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node,
      label: PropTypes.node,
      uri: PropTypes.string,
    }),
  ),
  link: PropTypes.any,
  preamble: PropTypes.node,
  styles: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.node,
};

export default withComponentDefaults(BoxNavigation, "boxNavigation");

function BoxNavigation({
  className,
  components: { Link = DefaultLink, Button = DefaultButton } = {
    Link: DefaultLink,
    Button: DefaultButton,
  },
  items,
  link,
  preamble,
  styles = defaultStyles,
  title: siblingTitle,
  ...restProps
}) {
  if (items.length === 0) {
    return null;
  }
  const itemsToShow = items?.filter((item) => item.showInMenu);
  return (
    <div className={clsx(styles.component, className)} {...restProps}>
      {siblingTitle || preamble ? (
        <div className={clsx(styles.header)}>
          {siblingTitle ? (
            link ? (
              <Link className={clsx(styles.title)} to={link}>
                {siblingTitle}
              </Link>
            ) : (
              <H className={clsx(styles.title)}>{siblingTitle}</H>
            )
          ) : null}
          {preamble ? (
            <p className={clsx(styles.preamble)}>{preamble}</p>
          ) : null}
        </div>
      ) : null}
      {itemsToShow && itemsToShow.length ? (
        <div className={clsx(styles.body)}>
          <ul className={clsx(styles.list)}>
            {itemsToShow.map(({ title, uri, label }, index) => {
              return (
                <li key={index} className={clsx(styles.item)}>
                  <Button
                    className={clsx(
                      styles.link,
                      siblingTitle && styles.sibling,
                    )}
                    to={uri}
                  >
                    {label || title}
                  </Button>
                </li>
              );
            })}
          </ul>
        </div>
      ) : null}
    </div>
  );
}
