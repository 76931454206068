import ModuleController from "@municipio/gatsby-theme-basic/src/components/ModuleController";
import modularityAreaContext from "@municipio/gatsby-theme-basic/src/modularityAreaContext";
import modularityModuleContext from "@municipio/gatsby-theme-basic/src/modularityModuleContext";
import { MaybeFragment } from "@whitespace/components";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import useDynamicModules from "../../../hooks/useDynamicModules";

import * as defaultStyles from "./ModularityArea.module.css";
import ModularityGroupWrapper from "./ModularityGroupWrapper";

ModularityArea.propTypes = {
  area: PropTypes.any,
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
};

export default function ModularityArea({
  area = {},
  className,
  styles = defaultStyles,
  ...restProps
}) {
  const { modules } = area;
  if (!modules?.length) {
    return null;
  }

  let dynamicModules = useDynamicModules(modules, {
    keys: [],
    modules: [],
  });
  // TODO: Wrap in PageGridGroup components based on columnWidth

  return (
    <modularityAreaContext.Provider value={area}>
      {!!dynamicModules.keys && (
        <MaybeFragment
          as="div"
          className={clsx(styles.component, className)}
          {...restProps}
        >
          {dynamicModules.modules.map((group, index) => {
            const items = group.items;
            return (
              <ModularityGroupWrapper
                key={index}
                items={items}
                className={styles.group}
              >
                {items.map(
                  ({ hidden, module, columnWidth, ...rest }, index) => {
                    if (hidden || !module) {
                      return null;
                    }
                    if (hidden || !module) {
                      return null;
                    }

                    const col = columnWidth
                      ? columnWidth.replace("grid-md-", "col")
                      : "inherit";

                    return (
                      <modularityModuleContext.Provider
                        value={{ hidden, module, columnWidth, ...rest }}
                        key={index}
                      >
                        <div className={clsx(styles.item, styles[col])}>
                          <ModuleController module={module} />
                        </div>
                      </modularityModuleContext.Provider>
                    );
                  },
                )}
              </ModularityGroupWrapper>
            );
          })}
        </MaybeFragment>
      )}
    </modularityAreaContext.Provider>
  );
}
