// extracted by mini-css-extract-plugin
export var button = "BoxNavigationWithImage-module--button--eftvq";
export var icon = "BoxNavigationWithImage-module--icon--OXhqs";
export var image = "BoxNavigationWithImage-module--image--M7-eq";
export var navigationBox = "BoxNavigationWithImage-module--navigationBox--6y2eO";
export var navigationHeader = "BoxNavigationWithImage-module--navigationHeader--8VKhW";
export var navigationList = "BoxNavigationWithImage-module--navigationList--wYLEG";
export var navigationListItem = "BoxNavigationWithImage-module--navigationListItem--kWMiM";
export var preamble = "BoxNavigationWithImage-module--preamble--Nil6R";
export var row = "BoxNavigationWithImage-module--row--OMBcB";
export var rowWrapper = "BoxNavigationWithImage-module--rowWrapper--vq0F+";