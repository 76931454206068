/** @jsx jsx */
import {
  // css,
  jsx,
} from "@emotion/react";
import { useModularityModule } from "@municipio/gatsby-theme-basic/src/hooks";
import { withComponentDefaults } from "@whitespace/components";
import PropTypes from "prop-types";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Configure } from "react-instantsearch-hooks-web";

import SearchHit from "../../../../components/SearchHit";
import SearchHits from "../../../../components/SearchHits";
// import SearchPagination from "../../../../components/SearchPagination";
import SearchProvider from "../../../../components/SearchProvider";

PostsModuleFilterProvider.propTypes = {
  attributesForFaceting: PropTypes.any,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  components: PropTypes.objectOf(PropTypes.elementType),
  paramTypes: PropTypes.any,
  transformParams: PropTypes.func,
};

export default withComponentDefaults(
  PostsModuleFilterProvider,
  "postsModuleFilterProvider",
);

function PostsModuleFilterProvider({ children: render }) {
  const { t, i18n } = useTranslation();
  let { module } = useModularityModule();
  let contentType = module.modPostsDataSource.postsDataPostType;
  let hitsPerPage = module.modPostsDataSource.postsCount;

  return (
    <SearchProvider routing={false} skipSearchIf={() => false}>
      {() => (
        <Fragment>
          <Configure
            filters={`language:${i18n.language} AND contentType.name:${contentType.name}`}
            hitsPerPage={hitsPerPage}
          />
          <SearchHits hitComponent={SearchHit}>
            {({ hits }) => render({ hits })}
          </SearchHits>
          {/* <SearchPagination /> */}
        </Fragment>
      )}
    </SearchProvider>
  );
}
