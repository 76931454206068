// extracted by mini-css-extract-plugin
export var component = "FrontPageHeader-module--component--EPG3L";
export var form = "FrontPageHeader-module--form--utYM1";
export var gridMenu = "FrontPageHeader-module--gridMenu--L3F9M";
export var hero = "FrontPageHeader-module--hero--yPPUU";
export var image = "FrontPageHeader-module--image--pohYX";
export var input = "FrontPageHeader-module--input--lpFI3";
export var recommendationItem = "FrontPageHeader-module--recommendationItem--BS7z-";
export var recommendationList = "FrontPageHeader-module--recommendationList--sU4iU";
export var recommendationWrapper = "FrontPageHeader-module--recommendationWrapper--0pFJQ";
export var rekAI = "FrontPageHeader-module--rekAI--dLOFe";
export var search = "FrontPageHeader-module--search--21cpD";
export var submit = "FrontPageHeader-module--submit--T6iwN";
export var tabsMenu = "FrontPageHeader-module--tabsMenu--ERyVr";