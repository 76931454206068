import "./src/index.css";
import CookieConsent from "@whitespace/gatsby-plugin-cookie-consent/src/components";
import CookieConsentProvider from "@whitespace/gatsby-plugin-cookie-consent/src/CookieConsentProvider";
import useCookieConsentSettings from "@whitespace/gatsby-plugin-cookie-consent/src/hooks/useCookieConsentSettings";
import algoliasearch from "algoliasearch/lite";
import React from "react";

import { algoliaContext } from "./src/contexts";

function parseAlgoliaHosts(string) {
  return (string || "")
    .split(",")
    .map((specifier) => {
      specifier = new URL(specifier, location.origin).toString();
      try {
        let [, protocol, url] = specifier.match(/^(\w+):(.*)$/);
        return { protocol, url };
      } catch {
        return;
      }
    })
    .filter(Boolean);
}

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY,
  { hosts: parseAlgoliaHosts(process.env.GATSBY_ALGOLIA_HOSTS) },
);

let indexName = process.env.GATSBY_ALGOLIA_INDEX_NAME;

export const wrapRootElement = ({ element }) => {
  return (
    <CookieConsentProvider>
      <algoliaContext.Provider value={{ searchClient, indexName }}>
        {element}
      </algoliaContext.Provider>
    </CookieConsentProvider>
  );
};

function CookieConsentWrapper({ children }) {
  const { active, position, strings } = useCookieConsentSettings();
  return (
    <>
      {children}
      <CookieConsent
        position={position}
        cookieConsentSettings={strings}
        active={active}
      />
    </>
  );
}

export const wrapPageElement = ({ element }) => {
  return <CookieConsentWrapper>{element}</CookieConsentWrapper>;
};
