import PropTypes from "prop-types";
import React from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";

import { getSiteMetaData } from "../hooks/siteMeta";
import useOpenGraph from "../hooks/useOpenGraph";

SEO.propTypes = {
  title: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  isFrontPage: PropTypes.bool,
};

export default function SEO({ title, meta = [], isFrontPage }) {
  const {
    i18n: { language: lang },
  } = useTranslation();
  const { title: siteTitle, description } = getSiteMetaData();

  const { metaTitle, metaDescription, metaImage, metaUrl } = useOpenGraph({
    lang,
    title,
    description,
    meta,
  });

  const titleTemplate = isFrontPage ? siteTitle : `%s - ${siteTitle}`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle || title}
      titleTemplate={titleTemplate}
      defaultTitle={siteTitle}
      link={[
        {
          rel: `icon`,
          type: `image/png`,
          href: `/favicon.svg`,
        },
        {
          rel: `alternate icon`,
          href: `/favicon.ico`,
        },
        {
          rel: `mask-icon`,
          href: `/safari-pinned-tab.svg`,
          color: `#004071`,
        },
      ]}
      meta={[
        {
          name: `referrer`,
          content: `no-referrer`,
        },
        {
          name: `apple-mobile-web-app-capable`,
          content: `yes`,
        },
        {
          name: `apple-mobile-web-app-status-bar-style`,
          content: `white`,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: metaUrl,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          metaImage
            ? [
                {
                  property: "og:image",
                  content: metaImage.src,
                },
                {
                  property: "og:image:width",
                  content: metaImage.width,
                },
                {
                  property: "og:image:height",
                  content: metaImage.height,
                },
                {
                  name: "twitter:card",
                  content: "summary_large_image",
                },
              ]
            : [],
        )
        .concat(meta)}
    />
  );
}
