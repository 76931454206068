import { H, Section } from "@jfrk/react-heading-levels";
import { Icon, Link } from "@whitespace/components";
import { HTML } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import clsx from "clsx";
import Img from "gatsby-image";
import PropTypes from "prop-types";
import React from "react";

import * as defaultStyles from "./ContactCard.module.css";

ContactCard.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
  contact: PropTypes.shape({
    address: PropTypes.string,
    administrationUnit: PropTypes.node,
    email: PropTypes.string,
    firstName: PropTypes.string,
    image: PropTypes.any,
    lastName: PropTypes.string,
    openingHours: PropTypes.string,
    other: PropTypes.string,
    phoneNumbers: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        number: PropTypes.string.isRequired,
      }),
    ),
    socialMedia: PropTypes.arrayOf(
      PropTypes.shape({
        media: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
    ),
    visitingAddress: PropTypes.string,
    workTitle: PropTypes.string,
  }),
};

const SOCIAL_MEDIA_NAMES = {
  facebook: "Facebook",
  linkedin: "LinkedIn",
  twitter: "Twitter",
  instagram: "Instagram",
};

export default function ContactCard({
  className,
  contact,
  styles = defaultStyles,
  ...restProps
}) {
  const {
    address,
    administrationUnit,
    email,
    firstName,
    image,
    lastName,
    openingHours,
    other,
    phoneNumbers,
    socialMedia,
    visitingAddress,
    workTitle,
  } = contact;

  return (
    <address
      itemScope=""
      itemType="http://schema.org/Person"
      className={clsx(styles.component, className)}
      {...restProps}
    >
      <div className={clsx(styles.wrapper)}>
        {image && (
          <div className={clsx(styles.image)}>
            <Img
              fixed={image}
              alt={lastName ? `${firstName} ${lastName}` : firstName}
            />
          </div>
        )}
        <div className={clsx(styles.contactInfo)}>
          <H itemProp="name" className={clsx(styles.name)}>
            {lastName ? `${firstName} ${lastName}` : firstName}
          </H>
          {workTitle && (
            <p className={clsx(styles.content)} itemProp="jobTitle">
              {workTitle}
            </p>
          )}
          {administrationUnit && (
            <p className={clsx(styles.content)} itemProp="jobTitle">
              {administrationUnit}
            </p>
          )}
        </div>
      </div>
      <Section>
        <div className={clsx(styles.columns)}>
          {email && (
            <div>
              <span className={clsx(styles.label)}>E-post</span>
              <Link
                className={clsx(styles.content, styles.link)}
                href={`mailto:${email}`}
                itemProp="email"
              >
                <Icon name="email-bold" className={styles.iconLabel} />
                <span>{email}</span>
              </Link>
            </div>
          )}
          {phoneNumbers &&
            phoneNumbers.map(({ type, number }, index) => {
              if (!number) {
                return null;
              }
              let label = type == "phone" ? "Telefonnummer" : "Mobilnummer";
              return (
                <div key={index}>
                  <span className={clsx(styles.label)}>{label}</span>
                  <div className={clsx(styles.content)} itemProp="telephone">
                    <Icon name={`${type}-bold`} className={styles.iconLabel} />
                    <Link href={`tel: ${number.replaceAll(/[^0-9]/gi, "")}`}>
                      {number}
                    </Link>
                  </div>
                </div>
              );
            })}
          {socialMedia && (
            <div className={clsx(styles.wrapper)}>
              {socialMedia.map(({ media, url }, index) => {
                return (
                  url && (
                    <Link
                      href={url}
                      key={index}
                      className={clsx(styles.label, styles.link)}
                    >
                      <Icon name={`${media}-bold`} />
                      <span>{SOCIAL_MEDIA_NAMES[media]}</span>
                    </Link>
                  )
                );
              })}
            </div>
          )}

          {address && (
            <div>
              <H className={clsx(styles.label)}>Adress</H>
              <HTML className={clsx(styles.content)}>{address}</HTML>
            </div>
          )}
          {visitingAddress && (
            <div>
              <H className={clsx(styles.label)}>Besöksadress</H>
              <HTML className={clsx(styles.content)}>{visitingAddress}</HTML>
            </div>
          )}
          {openingHours && (
            <div>
              <H className={clsx(styles.label)}>Öppettider</H>
              <HTML className={clsx(styles.content)}>{openingHours}</HTML>
            </div>
          )}
          {other && (
            <div>
              <HTML className={clsx(styles.content)}>{other}</HTML>
            </div>
          )}
        </div>
      </Section>
    </address>
  );
}
