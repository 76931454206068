/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Icon } from "@whitespace/components";
import HTML from "@whitespace/gatsby-theme-wordpress-basic/src/components/HTML";
import clsx from "clsx";
import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import * as defaultStyles from "./AlertBanner.module.css";
import LayoutComponent from "./LayoutComponent";

AlertBanner.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
};

export default function AlertBanner({ styles = defaultStyles }) {
  let data = useStaticQuery(graphql`
    query MyQuery {
      wp {
        alerts(first: 1000) {
          nodes {
            id
            title
            content
            alertSettings {
              alertSettingsType
            }
          }
        }
      }
    }
  `);
  let alerts = data.wp?.alerts?.nodes;

  if (alerts?.length === 0) {
    return null;
  }

  return (
    <>
      {alerts.map(
        ({ id, title, content, alertSettings: { alertSettingsType } }) => {
          return (
            <div
              key={id}
              className={styles.component}
              css={{
                background: `var(--color-${alertSettingsType})`,
              }}
            >
              <LayoutComponent
                className={clsx(styles.headerWrapper, styles.wrapper)}
                type={"fullNoMargin"}
              >
                <div className={styles.alert}>
                  <Icon className={styles.icon} name={alertSettingsType} />
                  <span className={styles.title}>{title}</span>
                  <HTML>{content}</HTML>
                </div>
              </LayoutComponent>
            </div>
          );
        },
      )}
    </>
  );
}
