/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Section } from "@jfrk/react-heading-levels";
import usePages from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/pages";
import { getChildren } from "@whitespace/gatsby-theme-wordpress-basic/src/utils/pageTree";
import React from "react";

import { useRekai } from "../../../../src/hooks/rekai";
import BoxNavigationWithImage from "../../../components/BoxNavigation/BoxNavigationWithImage";
import LandingPageHero from "../../../components/LandingPageHero";
import { sortItemsByTitle } from "../../../utils/sort";
import { usePageContext } from "../hooks";

export default function LandingPage() {
  const {
    contentNode: { id: pageId, title, serviceLink, uri },
  } = usePageContext();

  const allPages = usePages();

  const childPages = getChildren(allPages, pageId)
    .filter((page) => page.showInMenu)
    .map((page) => ({
      ...page,
      preamble: page?.menu?.menuDescription,
      items: getChildren(allPages, page.id)
        .filter((page) => page.showInMenu)
        .map((page) => ({
          ...page,
        })),
    }));

  const { predictions: shortcuts } =
    useRekai({ nrofhits: 7, subtree: uri }) || {};

  return (
    <>
      <LandingPageHero
        title={title}
        shortcuts={
          shortcuts && shortcuts.length > 0 && sortItemsByTitle(shortcuts)
        }
        serviceLink={
          serviceLink
            ? {
                url: serviceLink.url,
                title: `E-tjänster för ${title}`,
              }
            : null
        }
      />
      <Section>
        {childPages.map(
          ({ title, uri, preamble, items, featuredImage }, index) => {
            return (
              <BoxNavigationWithImage
                key={index}
                title={title}
                image={featuredImage?.node}
                link={uri}
                preamble={preamble}
                items={items}
              />
            );
          },
        )}
      </Section>
    </>
  );
}
