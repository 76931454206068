import { Icon, Link } from "@whitespace/components";
import { useMenu } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/menus";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import * as defaultStyles from "./HeaderHelpMenu.module.css";

HeaderHelpMenu.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function HeaderHelpMenu({
  styles = defaultStyles,
  className,
  ...restProps
}) {
  const { t } = useTranslation();
  const mobileHelpMenu = useMenu("MOBILE_HELP_MENU");
  const helpMenu = useMenu("HELP_MENU");

  const items = mobileHelpMenu?.items || helpMenu?.items;

  if (!items || !items.length) return null;

  return (
    <nav
      aria-label={t("Help menu")}
      className={clsx(styles.component, className)}
      {...restProps}
    >
      <ul className={styles.list}>
        {items.map((item, index) => {
          return (
            <li key={index} className={styles.item}>
              <Link className={styles.link} to={item.url}>
                {item.icon && item.icon !== "inherit" && (
                  <Icon className={styles.icon} name={item.icon} />
                )}
                {item.label}
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}
