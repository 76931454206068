import { useLocation } from "@gatsbyjs/reach-router";
import { H } from "@jfrk/react-heading-levels";
import { Button, Icon, withComponentDefaults } from "@whitespace/components";
import { usePrevious } from "@whitespace/gatsby-hooks";
import { useMenu } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/menus";
import usePages from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/pages";
import { getTreeStructure } from "@whitespace/gatsby-theme-wordpress-basic/src/utils/pageTree";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import * as defaultStyles from "./HeaderFlyOutMenu.module.css";
import HeaderHelpMenu from "./HeaderHelpMenu";
import TreeMenu from "./TreeMenu.js";
import * as treeMenuStyles from "./TreeMenu.module.css";

export default withComponentDefaults(HeaderFlyOutMenu, "headerFlyOutMenu");

HeaderFlyOutMenu.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

function HeaderFlyOutMenu({ styles = defaultStyles, ...restProps }) {
  const location = useLocation();
  const prevLocation = usePrevious(location);
  const allPages = usePages();
  const items = getTreeStructure(allPages);
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();

  const shortcuts = useMenu("HELP_MENU");

  useEffect(() => {
    if (location !== prevLocation) {
      setExpanded(false);
    }
  }, [location, prevLocation]);

  useEffect(() => {
    document.body.style.overflow = expanded ? "hidden" : null;
  }, [expanded]);

  return (
    <div {...restProps}>
      <Button
        onClick={() => setExpanded(!expanded)}
        className={styles.toggle}
        aria-label={t("menu")}
        aria-expanded={expanded}
      >
        <Icon className={styles.icon} name="icon-menu" />
        <span className={clsx(styles.label)}>{t("menu")}</span>
      </Button>
      {expanded && (
        <div>
          <div className={styles.backdrop} onClick={() => setExpanded(false)} />
          <div className={clsx(styles.menu)} role="dialog" aria-modal="true">
            <div className={clsx(styles.header)}>
              <H className={clsx(styles.title)}>{t("menu")}</H>
              <Button
                className={clsx(styles.closeBtn)}
                aria-label="Close menu"
                onClick={() => {
                  setExpanded(false);
                }}
              >
                <Icon
                  className={styles.closeIcon}
                  name="icon-close"
                  aria-label={t("close")}
                />
              </Button>
            </div>
            <div className={clsx(styles.content)}>
              <TreeMenu
                styles={treeMenuStyles}
                items={items}
                location={location}
              />
              {shortcuts?.items?.length && (
                <div>
                  <HeaderHelpMenu
                    styles={{
                      component: clsx(styles.helpMenu, styles.isBigScreen),
                      list: styles.helpMenuList,
                      item: styles.helpMenuItem,
                      link: styles.helpMenuLink,
                      icon: styles.helpMenuIcon,
                    }}
                  />
                </div>
              )}
            </div>
            {shortcuts?.items?.length && (
              <div>
                <HeaderHelpMenu
                  styles={{
                    component: clsx(styles.helpMenu, styles.isSmallScreen),
                    list: styles.helpMenuList,
                    item: styles.helpMenuItem,
                    link: styles.helpMenuLink,
                    icon: styles.helpMenuIcon,
                  }}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
