import ModularityArea from "@municipio/gatsby-theme-basic/src/components/ModularityArea";
import { SkipTo, Button, Icon } from "@whitespace/components";
import HeaderLogo from "@whitespace/gatsby-theme-wordpress-basic/src/components/HeaderLogo";
import HeaderMainMenu from "@whitespace/gatsby-theme-wordpress-basic/src/components/HeaderMainMenu";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/page-context";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import FrontPageHeader from "../../../components/FrontPageHeader";
import LayoutComponent from "../../../components/LayoutComponent";

import * as defaultStyles from "./Header.module.css";
import HeaderFlyOutMenu from "./HeaderFlyOutMenu/HeaderFlyOutMenu";

Header.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function Header({
  styles = defaultStyles,
  className,
  ...restProps
}) {
  const { contentNode: { isFrontPage, sliderArea } = {} } = usePageContext();

  const { t } = useTranslation();
  return (
    <header className={clsx(styles.component, className)} {...restProps}>
      <SkipTo />
      <div className={styles.header}>
        <LayoutComponent
          className={clsx(styles.headerWrapper, styles.wrapper)}
          type={"fullNoMargin"}
        >
          <HeaderLogo linkTo="/" className={styles.logo} />
          <HeaderMainMenu
            aria-label={t("helpMenu")}
            menuName={"helpMenu"}
            className={clsx(styles.helpMenu)}
          />
          <div className={styles.actions}>
            <Button className={clsx(styles.button)} to="/sok">
              <Icon
                className={styles.icon}
                name="search"
                aria-label={t("search.label")}
              />
              <span className={styles.label}>{t("search.label")}</span>
            </Button>
            <HeaderFlyOutMenu />
          </div>
        </LayoutComponent>
      </div>

      {sliderArea && <ModularityArea area={sliderArea} />}
      {isFrontPage && <FrontPageHeader wrapperClassName={styles.item} />}
    </header>
  );
}
