/** @jsx jsx */
// import React from "react";
import { css, jsx } from "@emotion/react";
import cx from "classnames";

export default function RoundIcon({
  name,
  className,
  bgColor = "var(--color-primary)",
  color = "var(--color-text-inverse)",
  size,
  ...restProps
}) {
  return (
    <span
      className={cx("round-icon", className)}
      css={css`
        --icon-color: ${color};
        --icon-bg-color: ${bgColor};
        --icon-size: ${size};
      `}
      {...restProps}
    >
      <span
        className="c-icon"
        css={css`
          mask-image: ${name ? `url("/icons/${name}.svg")` : "none"};
        `}
      />
    </span>
  );
}
