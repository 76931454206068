import { H } from "@jfrk/react-heading-levels";
import { RoundIcon, Link } from "@whitespace/components";
import PropTypes from "prop-types";
import React from "react";

import Button from "../Button/Button";

import * as defaultStyles from "./BoxNavigationWithImage.module.css";

BoxNavigationWithImage.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.string,
  link: PropTypes.string,
  preamble: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
  image: PropTypes.object,
};

export default function BoxNavigationWithImage({
  image,
  title,
  preamble,
  link,
  items,
  styles = defaultStyles,
  ...restProps
}) {
  return (
    <div className={styles.row}>
      <div {...restProps} className={styles.rowWrapper}>
        {image && (
          <img
            base64=""
            src={image.src}
            srcSet={image.srcSet}
            aspectRatio={16 / 9}
            width={image.width}
            height={image.height}
            alt={image.altText}
            className={styles.image}
          />
        )}
        <div className={styles.navigationBox}>
          {title || preamble ? (
            <div className={styles.navigationHeader}>
              {title ? (
                link ? (
                  <>
                    <Button
                      className={styles.button}
                      title={title}
                      link={link}
                      buttonModifier="prominent-link"
                      iconModifier="primary"
                    />
                    <RoundIcon
                      className={styles.icon}
                      name={"arrow-right-with-tail"}
                    />
                  </>
                ) : (
                  <H>{title}</H>
                )
              ) : null}
              {preamble ? <p className={styles.preamble}>{preamble}</p> : null}
            </div>
          ) : null}
          {items && items.length ? (
            <ul className={styles.navigationList}>
              {items.map((item, index) => {
                return (
                  <li key={index} className={styles.navigationListItem}>
                    <Link to={item.uri}>{item.title}</Link>
                  </li>
                );
              })}
            </ul>
          ) : null}
        </div>
        <div className={styles.line} />
      </div>
    </div>
  );
}
